import { useEffect } from 'react'

const ProductTour = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location = '/homepage2'
    }
  }, [])
  return null
}

export default ProductTour
